import * as React from 'react';
import { Link } from "gatsby"
import { 
	Box,
	Grid,
	Typography,
} from '@mui/material';
import LogoDowin from "images/logodowin.png";
import LogoPremium from "images/premium.png";
import PapFooter from 'components/pap-footer.js';
import MediaQuery from 'react-responsive';

const imageLinks = {
	ios: 'https://linkmaker.itunes.apple.com/images/badges/en-us/badge_appstore-lrg.svg',
	android: 'https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg',
};
const pageStyles = {
	fontFamily: 'Quicksand',
};
const topBar = {
	height: '56px',
	marginTop: '0px',
	paddingLeft: '16px',
	marginBottom: '16px',
}
const imageContainer = {
	height: '36.7px',
}
const bigImageContainer = {
	height: '275px',
}
const verticalPadding40 = {
	paddingTop: '40px',
	paddingBottom: '40px',
}
const androidButton = {
	background: `url(${imageLinks['android']}) no-repeat`,
	backgroundSize: 'contain',
	display: 'inline-block',
	overflow: 'hidden',
	textDecoration: 'none',
	height: '100%',
	width: '100%',
	padding: '5px',
}
const iosButton = {
	background: `url(${imageLinks['ios']}) no-repeat`,
	backgroundSize: 'contain',
	display: 'inline-block',
	overflow: 'hidden',
	textDecoration: 'none',
	height: '100%',
	width: '100%',
	padding: '5px',
}
const containerStyles = {
	height: "75px",
	width: "155px",
	display: "inline-block",
}

export default function DownloadApp() {
	const handleMediaQueryChange = (matches) => {
		return matches;
	}

	return (
		<Grid container style={pageStyles} rowSpacing={0} columnSpacing={2}>
			<Grid item xs={12}>
				<Box>
					<Grid container spacing={2} style={topBar}>
						<Grid item xs={12}>
							<Link to="/">
								<img
									alt="dowin Logo"
									style={imageContainer}
									src={LogoDowin}
								/>
							</Link>
						</Grid>
					</Grid>
					<Grid style={verticalPadding40} justifyContent={'center'} textAlign={"center"} container spacing={2}>
						<Grid item xs={12}>
							<Typography fontFamily={"Quicksand"} fontWeight="600" fontSize={20}> Obtén nuestra app de dowin y comienza a ayudar: </Typography>
						</Grid>
						<Grid item xs={6}>
							<div style={containerStyles}>
								<a
									href="https://apps.apple.com/es/app/dowin/id1557940432"
									target="_blank"
									rel="noopener noreferrer"
									title="enlace App Store"
									style={iosButton}
								>
									&nbsp;
								</a>
							</div>
						</Grid>
						<Grid item xs={6}>
							<div style={containerStyles}>
								<a
									href="https://play.google.com/store/apps/details?id=com.premiumandpremium.premiumandpremiumapp"
									target="_blank" rel="noopener noreferrer"
									title="enlace Play Store"
									style={androidButton}
								>
									&nbsp;
								</a>
							</div>
						</Grid>
						<Grid item xs={12}>
							<Typography fontFamily={"Quicksand"} fontWeight="600" fontSize={20}> Una app de: </Typography>
							<img
								alt="Premium&Premium Logo"
								style={bigImageContainer}
								src={LogoPremium}
							/>
						</Grid>
					</Grid>
				</Box>
			</Grid>
			<MediaQuery maxWidth={1224} onChange={handleMediaQueryChange}>
				<PapFooter
					isMobile={true}
				/>
			</MediaQuery>
			<MediaQuery minWidth={1224} onChange={handleMediaQueryChange}>
				<PapFooter
					isMobile={false}
				/>
			</MediaQuery>
		</Grid>
	)
}